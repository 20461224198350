package com.fabiorosati.components.sections

import androidx.compose.runtime.*
import com.fabiorosati.*
import com.fabiorosati.common.Language
import com.fabiorosati.components.widgets.ClickableImage
import com.fabiorosati.components.widgets.HorizontalLine
import com.fabiorosati.components.widgets.SocialMedia
import com.fabiorosati.components.widgets.VerticalSpacer
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Span
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import org.jetbrains.compose.web.css.px


val FooterStyle = CssStyle.base {
    Modifier
        //.backgroundColor(colorMode.toSitePalette().nearBackground)
        .backgroundColor(Colors.Black)
        .padding(bottom = 1.5.cssRem, leftRight = 10.percent)
}

@Composable
fun Footer(modifier: Modifier = Modifier, language: Language) {
    Column(
        modifier = FooterStyle.toModifier().then(modifier),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        HorizontalLine()
        VerticalSpacer(1.5.cssRem)
        // VerticalSpacer(30.vw) // VW maintains the proportion
        Span(FooterTextStyle.toModifier().textAlign(TextAlign.Center).toAttrs()) {
            //val sitePalette = ColorMode.current.toSitePalette()
            val sitePalette = ColorMode.LIGHT.toSitePalette()
            SpanText("© 2024 Valkanorr. ")
            SpanText("Designed by ")
            Link(
                "https://www.linkedin.com/in/fabio-rosati-345878215",
                "Fabio Rosati",
                Modifier.setVariable(ColorVar, sitePalette.brand.primary).whiteSpace(WhiteSpace.NoWrap),
                variant = UncoloredLinkVariant
            )
            SpanText(". Built with ")
            Link(
                "https://github.com/varabyte/kobweb",
                "Kobweb",
                Modifier.setVariable(ColorVar, sitePalette.brand.primary),
                variant = UncoloredLinkVariant
            )
            SpanText(", icons by ")
            Link(
                "https://icons8.com",
                "Icons8",
                Modifier.setVariable(ColorVar, sitePalette.brand.primary),
                variant = UncoloredLinkVariant
            )
        }
    }
}