package com.fabiorosati.pages

import androidx.compose.runtime.*
import com.fabiorosati.common.LANGUAGE_KEY
import com.fabiorosati.common.Language
import com.fabiorosati.common.getTranslations
import com.fabiorosati.components.sections.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import kotlinx.browser.document
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.percent


@Page
@Composable
fun HomePage() {
    document.title = "Valkanorr"

    var storedLanguage = ""
    LaunchedEffect(storedLanguage) {
        storedLanguage = localStorage.getItem(LANGUAGE_KEY) ?: ""
    }
    var language by remember{ mutableStateOf(Language.getByName(storedLanguage)) }
    var topSection by remember{ mutableStateOf("home") }

    Box(
        Modifier
            .fillMaxWidth()
            .minHeight(100.percent)
            // Create a box with two rows: the main content (fills as much space as it can) and the footer (which reserves
            // space at the bottom). "min-content" means the use the height of the row, which we use for the footer.
            // Since this box is set to *at least* 100%, the footer will always appear at least on the bottom but can be
            // pushed further down if the first row grows beyond the page.
            // Grids are powerful but have a bit of a learning curve. For more info, see:
            // https://css-tricks.com/snippets/css/complete-guide-grid/
            .gridTemplateRows { size(1.fr); size(minContent) },
        contentAlignment = Alignment.Center
    ) {
        Column(
            // Isolate the content, because otherwise the absolute-positioned SVG above will render on top of it.
            // This is confusing but how browsers work. Read up on stacking contexts for more info.
            // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
            // Some people might have used z-index instead, but best practice is to avoid that if possible, because
            // as a site gets complex, Z-fighting can be a huge pain to track down.
            Modifier.fillMaxSize().gridRow(1),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            // -- Header -- //
            NavHeader(language,topSection) {
                language = it
                localStorage.setItem(LANGUAGE_KEY, it.name)
            }

            // -- Hero (band image) -- //
            Hero(
                id = "home",
                onNotifyTop = { topSection = "home" },
            )

            // -- Sections -- //
            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                AboutSection(
                    id = "about",
                    title = language.getTranslations().aboutTitle,
                    language = language,
                    onNotifyTop = { topSection = "about" },
                )

                VideoSection(
                    id = "video",
                    title = language.getTranslations().video,
                    onNotifyTop = { topSection = "video" },
                )

                DiscographySection(
                    id = "discography",
                    title = language.getTranslations().discography,
                    onNotifyTop = { topSection = "discography" },
                )

                NextShowsSection(
                    id = "nextshows",
                    title = language.getTranslations().nextShows,
                    language = language,
                    onNotifyTop = { topSection = "nextshows" },
                )

                ContactsSection(
                    id = "contacts",
                    title = language.getTranslations().contactsTitle,
                    language = language,
                    onNotifyTop = { topSection = "contacts" },
                )
            }
        }

        // -- Footer -- //
        // Associate the footer with the row that will get pushed off the bottom of the page if it can't fit.
        Footer(Modifier.fillMaxWidth().gridRow(2), language)
    }
}