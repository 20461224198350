package com.fabiorosati.language

abstract class Translations {
    abstract val home: String
    abstract val about: String
    abstract val aboutTitle: String
    abstract val discography: String
    abstract val nextShows: String
    abstract val nextShowsText: String
    abstract val nextShowsBook: String
    abstract val contacts: String
    abstract val contactsTitle: String
    abstract val social: String
    abstract val contactUs: String
    abstract val goUp: String
    abstract val gallery: String
    abstract val video: String
    abstract val aboutText: String
    abstract val aboutText2: String
    abstract val socialMedia: String
    abstract val loremIpsum: String
}

class English : Translations() {
    override val home: String = "Home"
    override val about: String = "About"
    override val aboutTitle: String = "About Valkanorr"
    override val discography: String = "Albums"
    override val nextShows: String = "Shows"
    override val nextShowsText: String = "We're working on tour 2025, dates will come soon!"
    override val nextShowsBook: String = "Do you want to add you event to our tour?"
    override val contacts: String = "Contacts"
    override val contactsTitle: String = "Contacts"
    override val social: String = "Social Media"
    override val contactUs: String = "Get in touch with us!"
    override val goUp: String = "Go Up"
    override val gallery: String = "Gallery"
    override val video: String = "Videos"
    override val aboutText: String = "The journey of Valkanorr begins in the summer of 2021. The desire to delve into a world of music and adventure spurs them to wield ancient battle instruments, cutting through the air with bagpipe notes and shaking the ground with thundering drums. No power, no amplification—only the strength of muscles and the push of lungs.\n" +
            "\nMelodically, the music of Valkanorr emerges from exploring and reinterpreting traditional medieval, Celtic, and Occitan melodies, but soon evolves into the creation of original songs. In 2024, they release their first album, \"Di Fuoco e Terra\" (“Of Fire and Earth”), an eight-track journey that winds through mystical atmospheres, pulsing rhythms, and ballads made for big gatherings around the fire.\n" +
            "\nHowever, it's in live performances that the group truly expresses itself and conveys its essence: whether it's an acoustic set at a medieval fair, an amplified show on stage, or a spontaneous street performance, Valkanorr shines most when sharing moments of celebration. When the time comes to spread joy, Valkanorr is always ready to grab their instruments and push through the crowd to release their energy!\n" +
            "\nTheir drive to take on new challenges is just as strong: in the summer of 2024, Valkanorr decides to participate in the European Celtic Contest, standing out among over 40 bands from 7 different European countries and winning first place in the competition organized by the Montelago Celtic Festival, the largest Celtic music festival in Italy!\n" +
            "\nWant to book us for an event or get more information? Visit the \""
    override val aboutText2: String = "\" section, where you can find all the details to get in touch. We’ll be happy to answer any questions you have!"
    override val socialMedia: String = "Find us un social media!"
    override val loremIpsum: String =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a consequat odio, id posuere augue. Morbi eget lacus a justo placerat dignissim quis vitae felis. Nunc quis felis vitae lorem venenatis lobortis. Aenean ut turpis ut dolor varius faucibus vitae quis est. Pellentesque ultricies id justo sed dapibus. Nam auctor malesuada tincidunt. Suspendisse sit amet dolor odio. Ut venenatis neque non lacus accumsan, a dignissim lacus varius.\n" +
        "\nNunc in sagittis quam, ut lobortis sapien. Donec non elit justo. Maecenas cursus vehicula quam ut bibendum. Nullam varius sem ultrices aliquam aliquet. Duis placerat eu leo ut porttitor. Proin at mauris lacinia, molestie enim vitae, lobortis neque. Curabitur eu egestas elit, et posuere enim. Aenean vulputate, velit eu auctor iaculis, justo odio sollicitudin est, volutpat egestas eros purus eu felis. Etiam ullamcorper tincidunt justo eget ullamcorper."
}

class Italian : Translations() {
    override val home: String = "Home"
    override val about: String = "Chi siamo"
    override val aboutTitle: String = "Chi siamo"
    override val discography: String = "Album"
    override val nextShows: String = "Spettacoli"
    override val nextShowsText: String = "Stiamo lavorando sul tour 2025, le date arriveranno presto!"
    override val nextShowsBook: String = "Vuoi aggiungere il tuo evento al nostro tour?"
    override val contacts: String = "Contatti"
    override val contactsTitle: String = "Contatti"
    override val social: String = "Social"
    override val contactUs: String = "Contattaci!"
    override val goUp: String = "Torna su"
    override val gallery: String = "Galleria"
    override val video: String = "Video"
    override val aboutText: String = "Il viaggio dei Valkanorr ha inizio nell'estate del 2021. Il desiderio di addentrarsi in un mondo di musica e avventure li sprona a sfoderare antichi strumenti da battaglia per fendere l'aria con note di cornamusa e far tremare la terra con rombi di tamburi. Niente corrente, niente amplificazione, solo la forza dei muscoli e la spinta dei polmoni.\n" +
            "\nSul piano melodico, la musica dei Valkanorr nasce esplorarando e reinterpretando melodie tradizionali medievali, celtiche e occitane, ma si evolve ben presto con la stesura di brani originali. Nel 2024 rilasciano il primo album \"Di Fuoco e Terra\", un viaggio in 8 tracce inedite che si snodano tra atmosfere mistiche, ritmi pulsanti e ballate per grandi feste davanti al fuoco.\n" +
            "\nÈ però nella musica dal vivo che il gruppo riesce davvero ad esprimersi e trasmettere la propria essenza: che si tratti di un'esibizione acustica ad una fiera medievale, uno spettacolo amplificato su un palco, o un'esibizione libera per le strade della città. Quando giunge il momento di condividere momenti di festa, i Valkanorr sono sempre pronti ad imbracciare gli strumenti e farsi largo tra la folla per far esplodere la propria energia!\n" +
            "\nAnche la voglia di mettersi in gioco non manca di certo: nell'estate del 2024 i Valkanorr decidono di prendere parte all'European Celtic Contest, distinguendosi tra oltre 40 band iscritte da ben 7 paesi europei, e aggiudicandosi il 1° posto della competizione organizzata dal Montelago Celtic Festival, il più grande festival di musica celtica in tutta Italia!\n" +
            "\nVolete ingaggiarci per un evento o avere più informazioni? Nella sezione \""
    override val aboutText2: String = "\" potete trovare tutti i recapiti per mettervi in contatto con noi. Saremo felici di rispondere a tutte le vostre domande!"
    override val socialMedia: String = "Raggiungeteci sui nostri social!"
    override val loremIpsum: String =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a consequat odio, id posuere augue. Morbi eget lacus a justo placerat dignissim quis vitae felis. Nunc quis felis vitae lorem venenatis lobortis. Aenean ut turpis ut dolor varius faucibus vitae quis est. Pellentesque ultricies id justo sed dapibus. Nam auctor malesuada tincidunt. Suspendisse sit amet dolor odio. Ut venenatis neque non lacus accumsan, a dignissim lacus varius.\n" +
                "\nNunc in sagittis quam, ut lobortis sapien. Donec non elit justo. Maecenas cursus vehicula quam ut bibendum. Nullam varius sem ultrices aliquam aliquet. Duis placerat eu leo ut porttitor. Proin at mauris lacinia, molestie enim vitae, lobortis neque. Curabitur eu egestas elit, et posuere enim. Aenean vulputate, velit eu auctor iaculis, justo odio sollicitudin est, volutpat egestas eros purus eu felis. Etiam ullamcorper tincidunt justo eget ullamcorper."
}

class Piedmonteis : Translations() {
    override val home: String = "Ca"
    override val about: String = "Informassion"
    override val aboutTitle: String = "TODO"
    override val discography: String = "Mùsica"
    override val nextShows: String = "Spetàcoli"
    override val nextShowsText: String = "Stiamo lavorando sul tour 2025, le date arriveranno presto!"
    override val nextShowsBook: String = "Vuoi ingaggiarci per un evento?"
    override val contacts: String = "Arcàpit"
    override val contactsTitle: String = "Arcàpit"
    override val contactUs: String = "Contattaci!"
    override val social: String = "Contattaci!"
    override val goUp: String = "Gaute"
    override val gallery: String = "Fòto"
    override val video: String = "Vìdeo"
    override val aboutText: String = "TODO"
    override val aboutText2: String = "TODO"
    override val socialMedia: String = "TODO"
    override val loremIpsum: String =
        "Avei na fumna grasa, l'è na gran disperasiun, a ma sfunda la pajiasa,a ma sfunda la pajiasa, Avei na fumna grasa l'è na gran disperasiun, a ma sfunda la pajiasa e am fa mac giré i butun!\n" +
        "\nOi madama ca ciapa ca pia ca porta mac va ca fasa attensiun ca je'l civic an's al cantun ca je'l civic an's al cantun Oi madama ca ciapa ca pia ca porta mac va ca fasa attensiun ca je'l civic an's al cantun caij fa la cuntravensiun!"
}