package com.fabiorosati.components.sections

import androidx.compose.runtime.Composable
import com.fabiorosati.HeaderHeight
import com.fabiorosati.HeadlineTextStyle
import com.fabiorosati.HomeSectionStyle
import com.varabyte.kobweb.compose.css.BackgroundClip
import com.varabyte.kobweb.compose.css.BackgroundPosition
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.functions.CSSUrl
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.FitWidthImageVariant
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.graphics.ImageStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div


@Composable
fun HomeSection(
    id: String,
    title: String? = null,
    backgroundImage: String? = null,
    darkBackgroundAlpha: Int = 150,
    onNotifyTop: () -> Unit = {},
    bottomSection: Boolean = false,
    content: @Composable () -> Unit
) {
    window.addEventListener("scroll", {
        val item = document.getElementById(id)
        val sectionTop = item?.getBoundingClientRect()?.top
        val sectionBottom = item?.getBoundingClientRect()?.bottom

        if(!bottomSection) {
            if (sectionTop != null && sectionTop < HeaderHeight.value + 1)
                onNotifyTop()
        } else {
            if (sectionBottom != null && sectionBottom < window.innerHeight)
                onNotifyTop()
        }
    })

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .attrsModifier { id(id) }
            //.backgroundImage(linearGradient(LinearGradient.Direction.ToRight, Colors.LightBlue, Colors.LightGreen))
            .scrollMargin(top = HeaderHeight),
        contentAlignment = Alignment.Center
    ) {
        backgroundImage?.let {
            // -- For small screens -- /
            Box(Modifier.fillMaxSize().displayUntil(Breakpoint.MD)
                .backgroundImage(url(it))
                .backgroundPosition(BackgroundPosition.of(CSSPosition.Top))
            )

            // -- For medium screens -- /
            Box(Modifier.fillMaxSize().displayIfAtLeast(Breakpoint.MD)
                .backgroundImage(url(it))
            )

            //Box(Modifier.opacity(70.percent).fillMaxSize().backgroundImage(url("/texture.jpg"))) // Opacity is not working properly
            Box(Modifier.fillMaxSize().backgroundColor(Colors.Black.copy(alpha = darkBackgroundAlpha)))
        }

        Column(
            modifier = HomeSectionStyle.toModifier(),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            title?.let { title ->
                Div(
                    HeadlineTextStyle.toModifier()
                        .align(Alignment.CenterHorizontally)
                        .padding(bottom = 24.px)
                        .toAttrs()
                ) {
                    SpanText(title.uppercase())
                }
            }
            content()
        }
    }
}