package com.fabiorosati.components.sections

import androidx.compose.runtime.Composable
import com.fabiorosati.SubheadlineTextStyle
import com.fabiorosati.common.Language
import com.fabiorosati.common.getTranslations
import com.fabiorosati.components.widgets.ClickableImage
import com.fabiorosati.components.widgets.SocialMedia
import com.fabiorosati.components.widgets.VerticalSpacer
import com.fabiorosati.toSitePalette
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.LinkStyle
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.selectors.link
import com.varabyte.kobweb.silk.style.selectors.visited
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div


@Composable
fun ContactsSection(
    id: String,
    title: String,
    language: Language,
    onNotifyTop: () -> Unit = {},
) {
    HomeSection(
        id = id,
        title = title,
        bottomSection = true,
        onNotifyTop = { onNotifyTop() },
    ) {
        Div(SubheadlineTextStyle.toAttrs()) {
            Column (
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                Link(
                    path = "mailto:valkanorr@gmail.com",
                    text = "valkanorr@gmail.com",
                    variant = PrimaryLinkVariant
                )
                Link(
                    "tel:+393392461246",
                    "+39 3392461246",
                    variant = PrimaryLinkVariant
                )

                Box(Modifier.height(24.px))

                Row(
                    horizontalArrangement = Arrangement.spacedBy(24.px)
                ) {
                    ClickableImage(
                        src = "/icons/whatsapp-white.svg",
                        hoverSrc = "/icons/whatsapp-primary.svg",
                        description = "whatsapp icon",
                        path = "https://api.whatsapp.com/send?phone=393392461246"
                    )
                    ClickableImage(
                        src = "/icons/messenger-white.svg",
                        hoverSrc = "/icons/messenger-primary.svg",
                        description = "messenger icon",
                        path = "https://m.me/valkanorr"
                    )
                }

                VerticalSpacer(24.px)

                SpanText(language.getTranslations().socialMedia)

                VerticalSpacer(24.px)

                SocialMedia()
            }
        }
    }
}

val PrimaryLinkVariant = LinkStyle.addVariant {
    val colorModifier = Modifier.color(ColorVar.value())
    val sitePalette = ColorMode.LIGHT.toSitePalette()
    link { colorModifier }
    visited { colorModifier }
    hover {
        Modifier
            .setVariable(ColorVar, sitePalette.brand.primary)
            .whiteSpace(WhiteSpace.NoWrap)
            .textDecorationLine(TextDecorationLine.None)
    }
}