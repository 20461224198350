package com.fabiorosati.components.widgets

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOut
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.px

@Composable
fun ClickableImage(
    src: String,
    path: String,
    hoverSrc: String,
    description: String,
) {
    var hovering by remember { mutableStateOf(false) }
    Link(path = path) {
        Image(
            modifier = Modifier
                .size(24.px) // Fix size to avoid flickering due to dynamic src
                .onMouseOver { hovering = true  }
                .onMouseOut { hovering = false }
                .cursor(Cursor.Pointer),
            src = if(hovering) hoverSrc else src,
            description = description,
        )
    }
}