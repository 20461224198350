package com.fabiorosati.components.sections

import androidx.compose.runtime.Composable
import com.fabiorosati.HeaderHeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.scrollMargin
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import kotlinx.browser.document
import kotlinx.browser.window


@Composable
fun Hero(
    id: String,
    onNotifyTop: () -> Unit = {},
) {
    window.addEventListener("scroll", {
        val sectionTop = document.getElementById(id)?.getBoundingClientRect()?.top
        if(sectionTop != null && sectionTop <= HeaderHeight.value + 1)
            onNotifyTop()
    })

    Box(
        modifier = Modifier
            .attrsModifier { id(id) }
            .fillMaxWidth()
            .scrollMargin(top = HeaderHeight),
        contentAlignment = Alignment.TopCenter,
    ) {
        // -- For small screens -- /
        Image(
            modifier = Modifier.fillMaxWidth().displayUntil(Breakpoint.MD),
            src = "/hero-mobile.png",
            description = "Valkanorr band picture",
        )

        // -- For medium screens -- //
        Image(
            modifier = Modifier.fillMaxWidth().displayIfAtLeast(Breakpoint.MD),
            src = "/hero-desktop.png",
            description = "Valkanorr band picture",
        )
    }
}