package com.fabiorosati.common

import com.fabiorosati.language.English
import com.fabiorosati.language.Italian
import com.fabiorosati.language.Translations
import com.fabiorosati.language.Piedmonteis

// Note: When the user closes the side menu, we don't immediately stop rendering it (at which point it would disappear
// abruptly). Instead, we start animating it out and only stop rendering it when the animation is complete.
enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

enum class Language {
    ITA,
    ENG;
    //PIE;

    companion object {
        fun getByName(name: String) = entries.firstOrNull { it.name == name } ?: ITA
    }
}

fun Language.getTranslations(): Translations {
    return when(this) {
        Language.ITA -> Italian()
        Language.ENG -> English()
        //Language.PIE -> Piedmonteis()
    }
}