package com.fabiorosati.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import org.jetbrains.compose.web.css.px

@Composable
fun SocialMedia() {
    Row(
        horizontalArrangement = Arrangement.spacedBy(24.px),
        verticalAlignment = Alignment.CenterVertically,
    ) {
        ClickableImage(
            src = "/icons/instagram-white.svg",
            hoverSrc = "/icons/instagram-primary.svg",
            description = "instagram icon",
            path = "https://www.instagram.com/valkanorr"
        )
        ClickableImage(
            src = "/icons/facebook-white.svg",
            hoverSrc = "/icons/facebook-primary.svg",
            description = "facebook icon",
            path = "https://www.facebook.com/valkanorr"
        )
        ClickableImage(
            src = "/icons/youtube-white.svg",
            hoverSrc = "/icons/youtube-primary.svg",
            description = "youtube icon",
            path = "https://www.youtube.com/@valkanorr"
        )
        ClickableImage(
            src = "/icons/tiktok-white.svg",
            hoverSrc = "/icons/tiktok-primary.svg",
            description = "facebook icon",
            path = "https://www.tiktok.com/@valkanorr"
        )
    }
}