package com.fabiorosati.components.sections

import androidx.compose.runtime.Composable
import com.fabiorosati.SubheadlineTextStyle
import com.fabiorosati.common.Language
import com.fabiorosati.common.getTranslations
import com.fabiorosati.components.widgets.VerticalSpacer
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.Span


@Composable
fun NextShowsSection(
    id: String,
    title: String,
    language: Language,
    onNotifyTop: () -> Unit = {},
) {
    HomeSection(
        id = id,
        title = title,
        backgroundImage = "/valkanorr-shows.jpg",
        onNotifyTop = { onNotifyTop() },
    ) {
        Column(
            modifier = SubheadlineTextStyle.toModifier(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SpanText(language.getTranslations().nextShowsText)
            VerticalSpacer(3.cssRem)
            Span {
                SpanText(language.getTranslations().nextShowsBook + " ")
                Link(
                    path = "/#contacts",
                    text = language.getTranslations().contactUs,
                    variant = UncoloredLinkVariant
                )
            }
            VerticalSpacer(13.cssRem)
        }
    }
}