package com.fabiorosati.components.sections

import androidx.compose.runtime.Composable
import com.fabiorosati.SubheadlineTextStyle
import com.fabiorosati.common.Language
import com.fabiorosati.common.getTranslations
import com.varabyte.kobweb.compose.css.AlignContent
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toAttrs
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span


@Composable
fun AboutSection(
    id: String,
    title: String,
    language: Language,
    onNotifyTop: () -> Unit = {},
) {
    HomeSection(
        id = id,
        //title = title,
        onNotifyTop = { onNotifyTop() },
    ) {
        Column(
            verticalArrangement = Arrangement.spacedBy(24.px),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Span(SubheadlineTextStyle.toAttrs()) {
                SpanText(language.getTranslations().aboutText)
                Link(
                    path = "/#contacts",
                    text = language.getTranslations().contacts,
                    variant = UncoloredLinkVariant
                )
                SpanText(language.getTranslations().aboutText2)
            }

            SimpleGrid(
                modifier = Modifier
                    .columnGap(10.px)
                    .rowGap(10.px)
                    .alignContent(AlignContent.SpaceEvenly),
                numColumns = numColumns(1, md = 2)
            ) {
                Image(
                    src = "/valkanorr-pic-01.jpg",
                    description = "Valkanorr band picture",
                    modifier = Modifier.fillMaxSize().objectFit(ObjectFit.Cover)
                )
                Image(
                    src = "/valkanorr-pic-02.jpg",
                    description = "Valkanorr band picture",
                    modifier = Modifier.fillMaxSize().objectFit(ObjectFit.Cover)
                )
                Image(
                    src = "/valkanorr-pic-04.jpg",
                    description = "Valkanorr band picture",
                    modifier = Modifier.fillMaxSize().objectFit(ObjectFit.Cover)
                )
                Image(
                    src = "/valkanorr-pic-05.jpg",
                    description = "Valkanorr band picture",
                    modifier = Modifier.fillMaxSize().objectFit(ObjectFit.Cover)
                )
            }
        }
    }
}