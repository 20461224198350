package com.fabiorosati.components.sections

import androidx.compose.runtime.Composable
import com.fabiorosati.components.attributes.*
import com.varabyte.kobweb.compose.css.functions.url
import org.jetbrains.compose.web.dom.Iframe


@Composable
fun DiscographySection(
    id: String,
    title: String,
    onNotifyTop: () -> Unit = {},
) {
    HomeSection(
        id = id,
        title = title,
        backgroundImage = "/texture.jpg",
        darkBackgroundAlpha = 100,
        onNotifyTop = { onNotifyTop() },
    ) {
        Iframe({
            iframeStyle("border-radius:12px")
            src("https://open.spotify.com/embed/album/57CmwJ1wHaeuplHdc9L1Fa?utm_source=generator")
            iframeWidth("100%")
            iframeHeight("352")
            frameBorder("0")
            allowfullscreen("")
            allow("autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture")
            loading("lazy")
        })
    }
}