import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    AppGlobals.initialize(mapOf("title" to "Website Compose"))
    BasePath.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.fabiorosati.pages.HomePage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text", com.fabiorosati.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text", com.fabiorosati.SubheadlineTextStyle)
        ctx.theme.registerStyle("footer-text", com.fabiorosati.FooterTextStyle)
        ctx.theme.registerStyle("home-section", com.fabiorosati.HomeSectionStyle)
        ctx.theme.registerStyle("nav-header", com.fabiorosati.NavHeaderStyle)
        ctx.theme.registerStyle("nav-header-content", com.fabiorosati.NavHeaderContentStyle)
        ctx.theme.registerStyle("social-header", com.fabiorosati.SocialHeaderStyle)
        ctx.theme.registerStyle("hero-container", com.fabiorosati.HeroContainerStyle)
        ctx.theme.registerStyle("home-grid", com.fabiorosati.HomeGridStyle)
        ctx.theme.registerStyle("home-grid-cell", com.fabiorosati.HomeGridCellStyle)
        ctx.theme.registerStyle("footer", com.fabiorosati.components.sections.FooterStyle)
        ctx.theme.registerVariant("-circle", com.fabiorosati.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", com.fabiorosati.UncoloredButtonVariant
        )
        ctx.theme.registerVariant("-primary", com.fabiorosati.components.sections.PrimaryLinkVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                com.fabiorosati.components.sections.SideMenuSlideInAnim)
        com.fabiorosati.initColorMode(ctx)
        com.fabiorosati.initSiteStyles(ctx)
        com.fabiorosati.initTheme(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        com.fabiorosati.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
