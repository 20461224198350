package com.fabiorosati.components.widgets

import androidx.compose.runtime.*
import com.fabiorosati.SitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText

/**
 * Adds the visual effects of a clickable item on mouse hover
 * (pointer cursor and underlined text)
 */
@Composable
fun Modifier.clickable(): Modifier {
    var hovering by remember { mutableStateOf(false) }
    return Modifier
        .onMouseOver { hovering = true  }
        .onMouseOut { hovering = false }
        .cursor(Cursor.Pointer)
        .textDecorationLine(if(hovering) TextDecorationLine.Underline else TextDecorationLine.None)
}

@Composable
fun ClickableText(text: String, isSelected: Boolean = false, onClick: () -> Unit) {
    SpanText(
        text = text,
        modifier = Modifier
            .clickable()
            .color(if(isSelected) SitePalette.Brand().primary else Colors.White)
            //.fontWeight(if(isSelected) FontWeight.Bolder else FontWeight.Normal)
            .onClick { onClick() },
    )
}