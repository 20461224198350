package com.fabiorosati.components.attributes

import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.HTMLIFrameElement

fun AttrsScope<HTMLIFrameElement>.iframeStyle(value: String) =
    attr("style", value)

fun AttrsScope<HTMLIFrameElement>.src(value: String) =
    attr("src", value)

fun AttrsScope<HTMLIFrameElement>.allow(value: String) =
    attr("allow", value)

fun AttrsScope<HTMLIFrameElement>.loading(value: String) =
    attr("loading", value)

fun AttrsScope<HTMLIFrameElement>.iframeWidth(value: String) =
    attr("width", value)

fun AttrsScope<HTMLIFrameElement>.iframeHeight(value: String) =
    attr("height", value)

fun AttrsScope<HTMLIFrameElement>.frameBorder(value: String) =
    attr("frameBorder", value)

fun AttrsScope<HTMLIFrameElement>.allowfullscreen(value: String) =
    attr("allowfullscreen", value)

fun AttrsScope<HTMLIFrameElement>.referrerpolicy(value: String) =
    attr("referrerpolicy", value)
