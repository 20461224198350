package com.fabiorosati.components.sections

import androidx.compose.runtime.Composable
import com.fabiorosati.components.attributes.*
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Iframe


@Composable
fun VideoSection(
    id: String,
    title: String,
    onNotifyTop: () -> Unit = {},
) {
    HomeSection(
        id = id,
        title = title,
        backgroundImage = "/cover.jpg",
        onNotifyTop = { onNotifyTop() },
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            verticalArrangement = Arrangement.spacedBy(10.px)
        ) {
            Iframe({
                iframeWidth("100%")
                iframeHeight("452")
                src("https://www.youtube.com/embed/xaeZaCnWAD0?si=-KdsX6aHBhJxFv3-")
                title("YouTube video player")
                frameBorder("0")
                allow("accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share")
                referrerpolicy("strict-origin-when-cross-origin")
                allowfullscreen("")
            })
            Iframe({
                iframeWidth("100%")
                iframeHeight("452")
                src("https://www.youtube.com/embed/4293UVDCk_M?si=yoydU5CnfSRP9GBq")
                title("YouTube video player")
                frameBorder("0")
                allow("accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share")
                referrerpolicy("strict-origin-when-cross-origin")
                allowfullscreen("")
            })
        }
    }
}