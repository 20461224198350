package com.fabiorosati.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.jetbrains.compose.web.css.CSSNumericValue
import org.jetbrains.compose.web.css.CSSUnitLengthOrPercentage


@Composable
fun VerticalSpacer(
    modifier: Modifier = Modifier,
    height: CSSNumericValue<out CSSUnitLengthOrPercentage>
) {
    Box(modifier.height(height))
}

@Composable
fun VerticalSpacer(
    height: CSSNumericValue<out CSSUnitLengthOrPercentage>
) {
    Box(Modifier.height(height))
}

@Composable
fun HorizontalSpacer(
    modifier: Modifier = Modifier,
    width: CSSNumericValue<out CSSUnitLengthOrPercentage>
) {
    Box(modifier.width(width))
}

@Composable
fun HorizontalSpacer(
    width: CSSNumericValue<out CSSUnitLengthOrPercentage>
) {
    Box(Modifier.width(width))
}