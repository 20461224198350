package com.fabiorosati.components.sections

import androidx.compose.runtime.*
import com.fabiorosati.NavHeaderContentStyle
import com.fabiorosati.NavHeaderStyle
import com.fabiorosati.SitePalette
import com.fabiorosati.common.Language
import com.fabiorosati.common.SideMenuState
import com.fabiorosati.common.getTranslations
import com.fabiorosati.components.widgets.ClickableText
import com.fabiorosati.components.widgets.IconButton
import com.fabiorosati.toSitePalette
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.CloseIcon
import com.varabyte.kobweb.silk.components.icons.HamburgerIcon
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.*


@Composable
fun NavHeader(
    language: Language,
    selectedSection: String,
    onLanguageChanged: (Language) -> Unit)
{
    Box(
        modifier = NavHeaderStyle.toModifier(),
        contentAlignment = Alignment.Center
    ) {
        val contentModifier = NavHeaderContentStyle.toModifier()
            .fontSize(0.9.cssRem)
            .fontWeight(FontWeight.SemiBold)

        // -- For small screens -- /
        SmallHeader(
            modifier = contentModifier.displayUntil(Breakpoint.MD),
            language = language,
            selectedSection = selectedSection
        ) {
            onLanguageChanged(it)
        }

        // -- For medium screens -- //
        MediumHeader(
            modifier = contentModifier.displayIfAtLeast(Breakpoint.MD),
            language = language,
            selectedSection = selectedSection
        ) {
            onLanguageChanged(it)
        }
    }
}

@Composable
private fun SmallHeader(
    modifier: Modifier = Modifier,
    language: Language,
    selectedSection: String,
    onLanguageChanged: (Language) -> Unit
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        // -- Language selector -- //
        LanguageSelector(language) {
            onLanguageChanged(it)
        }

        Spacer()

        // -- Section selector -- //
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(1.cssRem),
        ) {
            var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }

            // Menu icon
            HamburgerButton(onClick = { menuState = SideMenuState.OPEN })

            // Actual menu
            if (menuState != SideMenuState.CLOSED) {
                SideMenu(
                    menuState = menuState,
                    language = language,
                    selectedSection = selectedSection,
                    close = { menuState = menuState.close() },
                    onAnimationEnd = { if (menuState == SideMenuState.CLOSING) menuState = SideMenuState.CLOSED }
                )
            }
        }
    }
}

@Composable
private fun MediumHeader(
    modifier: Modifier = Modifier,
    language: Language,
    selectedSection: String,
    onLanguageChanged: (Language) -> Unit
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        // -- Section selector -- //
        HorizontalMenu(
            modifier = Modifier.displayIfAtLeast(Breakpoint.MD),
            language = language,
            selectedSection = selectedSection
        )

        Spacer()

        // -- Language selector -- //
        LanguageSelector(language) {
            onLanguageChanged(it)
        }
    }
}

@Composable
private fun SideMenu(
    menuState: SideMenuState,
    language: Language,
    selectedSection: String,
    close: () -> Unit,
    onAnimationEnd: () -> Unit
) {
    Overlay(
        Modifier
            .setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
            .onClick { close() }
    ) {
        key(menuState) { // Force recompute animation parameters when close button is clicked
            Column(
                Modifier
                    .fillMaxHeight()
                    //.width(clamp(8.cssRem, 33.percent, 10.cssRem))
                    .align(Alignment.CenterEnd)
                    // Close button will appear roughly over the hamburger button, so the user can close
                    // things without moving their finger / cursor much.
                    .padding(top = 1.cssRem, leftRight = 1.cssRem)
                    .gap(1.5.cssRem)
                    .backgroundColor(ColorMode.current.toSitePalette().nearBackground)
                    .animation(
                        SideMenuSlideInAnim.toAnimation(
                            duration = 200.ms,
                            timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
                            direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
                            fillMode = AnimationFillMode.Forwards
                        )
                    )
                    .borderRadius(topLeft = 2.cssRem)
                    .onClick { it.stopPropagation() }
                    .onAnimationEnd { onAnimationEnd() },
                horizontalAlignment = Alignment.End
            ) {
                CloseButton(onClick = { close() })
                VerticalMenu(language,selectedSection)
            }
        }
    }
}

@Composable
private fun LanguageSelector(language: Language, onLanguageChanged: (Language) -> Unit) {
    Row(horizontalArrangement = Arrangement.spacedBy(12.px)) {
        Language.entries.forEach { lang ->
            ClickableLanguage(lang, language == lang) {
                onLanguageChanged(it)
            }
        }
    }
}

@Composable
private fun ClickableLanguage(language: Language, isSystemLanguage: Boolean, onClick: (Language) -> Unit) {
    ClickableText(language.name, isSystemLanguage) {
        onClick(language)
    }
}

@Composable
private fun NavLink(path: String, text: String) {
    Link(path, text, variant = UndecoratedLinkVariant.then(UncoloredLinkVariant))
}

@Composable
private fun HorizontalMenu(
    modifier: Modifier = Modifier,
    language: Language,
    selectedSection: String,
) {
    Row(
        modifier = modifier.fillMaxHeight(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(1.4.cssRem)
    ) {
        MenuItem(name = language.getTranslations().home, id = "home", isSelected = selectedSection == "home")
        MenuVerticalDivider()
        MenuItem(name = language.getTranslations().about, id = "about", isSelected = selectedSection == "about")
        MenuVerticalDivider()
        MenuItem(name = language.getTranslations().video, id = "video", isSelected = selectedSection == "video")
        MenuVerticalDivider()
        MenuItem(name = language.getTranslations().discography, id = "discography", isSelected = selectedSection == "discography")
        MenuVerticalDivider()
        MenuItem(name = language.getTranslations().nextShows, id = "nextshows", isSelected = selectedSection == "nextshows")
        MenuVerticalDivider()
        MenuItem(name = language.getTranslations().contacts, id = "contacts", isSelected = selectedSection == "contacts")
    }
}

@Composable
private fun VerticalMenu(
    language: Language,
    selectedSection: String,
) {
    Column(
        modifier = Modifier.padding(right = 0.75.cssRem),
        horizontalAlignment = Alignment.End,
        verticalArrangement = Arrangement.spacedBy(1.5.cssRem)
    ) {
        MenuItem(name = language.getTranslations().home, id = "home", isSelected = selectedSection == "home")
        MenuItem(name = language.getTranslations().about, id = "about", isSelected = selectedSection == "about")
        MenuItem(name = language.getTranslations().video, id = "video", isSelected = selectedSection == "video")
        MenuItem(name = language.getTranslations().discography, id = "discography", isSelected = selectedSection == "discography")
        MenuItem(name = language.getTranslations().nextShows, id = "nextshows", isSelected = selectedSection == "nextshows")
        MenuItem(name = language.getTranslations().contacts, id = "contacts", isSelected = selectedSection == "contacts")
    }
}

@Composable
private fun MenuItem(
    name: String,
    id: String,
    isSelected: Boolean = false,
    showSelector: Boolean = true,
) {
    val ctx = rememberPageContext()
    var hovering by remember { mutableStateOf(false) }
    Box(Modifier
        .fillMaxHeight()
        .onMouseOver { hovering = true  }
        .onMouseOut { hovering = false }
        .cursor(Cursor.Pointer)
        .onClick {
            ctx.router.tryRoutingTo("/#$id")
        }
    ) {
        SpanText(
            text = name.uppercase(),
            modifier = Modifier
                .color(if(hovering || isSelected) SitePalette.Brand().primary else Colors.White)
                .align(Alignment.Center)
        )
        if(showSelector) {
            Spacer()
            if(isSelected)
                Box(Modifier
                    .height(1.5.px)
                    .fillMaxWidth()
                    .backgroundColor(SitePalette.Brand().primary)
                    .align(Alignment.BottomCenter)
                )
        }
    }
}


@Composable
fun MenuVerticalDivider() {
    Box(Modifier.fillMaxHeight(15.percent).width(1.px).backgroundColor(Colors.White.copy(alpha = 90)))
}

@Composable
private fun HamburgerButton(onClick: () -> Unit) {
    IconButton(onClick) {
        HamburgerIcon()
    }
}

@Composable
private fun CloseButton(onClick: () -> Unit) {
    IconButton(onClick) {
        CloseIcon()
    }
}

val SideMenuSlideInAnim = Keyframes {
    from {
        Modifier.translateX(100.percent)
    }

    to {
        Modifier
    }
}